/*rss feed page*/
#posts_module .rss_link_image {
	word-break: break-all;
	display: inline-block;
}

@media (max-width: 991px) {
	/*list with images content box*/
	.posts-collection.homepage-contentbox.article-items {
		flex-direction: column;
		margin-bottom: 0;
	}

	.posts-collection.homepage-contentbox.article-items .article {
		display: block;
		margin-bottom: 40px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 0;
		padding-right: 0;
		width: 100% !important;
	}

	.posts-collection.homepage-contentbox.article-items .article-image-link {
		float: left;
		width: 50%;
	}

	.posts-collection.homepage-contentbox.article-items .article-content {
		float: right;
		padding-left: 20px;
		width: 50%;
	}

	.posts-collection.homepage-contentbox.article-items .article-title {
		margin-top: 0;
		max-width: none;
	}
}

@media (max-width: 767px) {
	/*subscribe page*/
	#posts_module.form .form_item .ui-form-error {
		margin-left: 0;
	}

	#posts_module.form .form_item {
		margin-top: 10px;
	}

	#posts_module.form .form_item:first-of-type {
		margin-top: 0;
	}

	#posts_module.form .form_item .required .ui-form-label {
		display: block;
		width: 100%;
		text-align: left;
	}

	#posts_module.form .form_item .required .ui-form-label label:after {
		content: "*";
		color: #ee0000;
		padding-left: 5px;
	}

	#posts_module.form .form_item .required .ui-form-input {
		background: 0 none;
		display: block;
		width: 100%;
		padding-left: 0;
	}

	#posts_module.form .ui-form-buttons {
		text-align: left;
	}

	#posts_module.form .form_item table {
		width: 100%;
	}
	/*list with images content box*/
	.posts-collection.homepage-contentbox.article-items .article {
		max-width: 400px;
	}

	.posts-collection.homepage-contentbox.article-items .article-image-link {
		display: block;
		float: none;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}

	.posts-collection.homepage-contentbox.article-items .article-content {
		float: none;
		padding-left: 0;
		width: 100%;
	}

	.posts-collection.homepage-contentbox.article-items .article-title {
		margin-top: 20px;
	}
}

@media (max-width: 479px) {
	#posts_module.form .form_item .required .ui-form-input input[type="text"] {
		width: 100%;
	}

	.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input,
	.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea {
		width: 100%;
	}
}
